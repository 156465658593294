import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Row } from '../components/helpers';
import MattressFirmBrand from '../../src/Icons/MattressFirmBrand';
import SleepDotComBrand from '../../src/Icons/SleepDotComBrand';
import BeautyRestBrand from '../../src/Icons/BeautyRestBrand';
import LullBrand from '../../src/Icons/LullBrand';
import KingsdownSelectBrand from '../../src/Icons/KingsdownSelectBrand';
import PurpleBrand from '../../src/Icons/PurpleBrand';
import SealyBrand from '../../src/Icons/SealyBrand';
import SertaBrand from '../../src/Icons/SertaBrand';
import SleepysBrand from '../../src/Icons/SleepysBrand';
import StearnsAndFosterBrand from '../../src/Icons/StearnsAndFosterBrand';
import TempurPedicBrand from '../../src/Icons/TempurPedicBrand';
import TuftAndNeedleBrand from '../../src/Icons/TuftAndNeedleBrand';
import IntellibedBrand from '../../src/Icons/IntellibedBrand';
import TuloBrand from '../../src/Icons/TuloBrand';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "icons"
    }}>{`Icons`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import IconName from 'mfcl/Icons/[Icon Name]'
`}</code></pre>
    <Playground __position={0} __code={'<Row>\n  <MattressFirmBrand />\n  <SleepDotComBrand fillColor=\"#000\" />\n</Row>\n<br />\n<Row>\n  <BeautyRestBrand />\n  <IntellibedBrand fillColor=\"#000\" />\n  <LullBrand height={60} width={92} />\n  <KingsdownSelectBrand />\n</Row>\n<br />\n<Row>\n  <PurpleBrand />\n  <SealyBrand />\n  <SertaBrand />\n  <SleepysBrand />\n</Row>\n<br />\n<Row>\n  <StearnsAndFosterBrand />\n  <TempurPedicBrand />\n  <TuftAndNeedleBrand />\n  <TuloBrand />\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Row,
      MattressFirmBrand,
      SleepDotComBrand,
      BeautyRestBrand,
      LullBrand,
      KingsdownSelectBrand,
      PurpleBrand,
      SealyBrand,
      SertaBrand,
      SleepysBrand,
      StearnsAndFosterBrand,
      TempurPedicBrand,
      TuftAndNeedleBrand,
      IntellibedBrand,
      TuloBrand,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <MattressFirmBrand mdxType="MattressFirmBrand" />
    <SleepDotComBrand fillColor="#000" mdxType="SleepDotComBrand" />
  </Row>
  <br />
  <Row mdxType="Row">
    <BeautyRestBrand mdxType="BeautyRestBrand" />
    <IntellibedBrand fillColor="#000" mdxType="IntellibedBrand" />
    <LullBrand height={60} width={92} mdxType="LullBrand" />
    <KingsdownSelectBrand mdxType="KingsdownSelectBrand" />
  </Row>
  <br />
  <Row mdxType="Row">
    <PurpleBrand mdxType="PurpleBrand" />
    <SealyBrand mdxType="SealyBrand" />
    <SertaBrand mdxType="SertaBrand" />
    <SleepysBrand mdxType="SleepysBrand" />
  </Row>
  <br />
  <Row mdxType="Row">
    <StearnsAndFosterBrand mdxType="StearnsAndFosterBrand" />
    <TempurPedicBrand mdxType="TempurPedicBrand" />
    <TuftAndNeedleBrand mdxType="TuftAndNeedleBrand" />
    <TuloBrand mdxType="TuloBrand" />
  </Row>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      